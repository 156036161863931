import React from "react";
import { StaffListFrontMatter } from "types/graphql";
import MemberBody from "../memberBody";
import Section from "../section";
import { Link } from "gatsby";
import BaseButton from "components/button/BaseButton";
import Star from "assets/svg/star.svg"
import classNames from "classnames/bind";
import ArrowRight from "assets/svg/arrow-right.svg";

type staffProps = Omit<StaffListFrontMatter, "format"> & {
  content: string;
};

const staffList = ({
  title,
  content,
  buttonlabel,
  staffMembers,
}: staffProps) => {

  const handleClickPlausible = () => {
    if ("plausible" in window) {
      window.plausible("join-us");
    }
  }

  return (
    <Section
      className="relative flex flex-col items-center justify-center py-10 lg:py-16 mb-10 lg:mb-20"
      full
    >
      <div className="flex flex-col items-center">
        <span className="h2 mb-4">{title}</span>
        {content && (
          <div
            className="mb-8"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {buttonlabel && (
          <BaseButton
            tag={Link}
            onClick={() => handleClickPlausible()}
            to="/contact?type=job"
            size="md"
            target="_blank"
            rel="noopener"
            role="button"
            className="arrow-left-in plausible-event-name=join+us"
          >
            <ArrowRight />
            {buttonlabel}
          </BaseButton>
          )}
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 w-full lg:gap-x-24 xl:gap-x-4 2xl:gap-x-16 gap-y-14 mt-10">
        {staffMembers.map((member: {id:number, image:string, text:string, name:string, language:string, star: boolean}, index: number) => {
          const { id, image, text, name, language, star } = member;
          return (
            <article key={id} className="text-center translate group" data-aos="custom">
              <MemberBody
                name={name}
                imageUrl={image}
                index={index}
                key={index}
                className="px-5 lg:px-10 xl:px-5 w-full"
              />
              <div>
                <h4 className="h4 text-gray-900 mt-3 lg:mt-6 mb-1">{name}</h4>
                <p className="text-b3 text-gray-700 mb-1">{text}</p>
                <span
                  className={classNames(
                    "inline-flex bg-gray-50 p-1 caption3 pr-3 uppercase rounded-full items-center gap-x-1",{
                      "pl-2" : star,
                      "pl-3" : !star
                    }
                  )}>
                  {language}
                </span>
              </div>
            </article>
          );
        })}
      </div>
    </Section>
  );
};

export default staffList;
