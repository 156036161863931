import React from "react";
import {Seo} from "components/seo";

import { graphql } from "gatsby";
import { globalQuery } from "types/graphql";
import ImageContent from "components/sections/imageContent";
import ListTitles from "components/sections/listTitles";
import TitleImageIntro from "components/sections/titleImageIntro";
import StaffList from "components/sections/staffList";
import staffList from "../content/about/staff.js";
import {StaticImage} from "gatsby-plugin-image";

export default function About({ data }: { data: globalQuery }) {
  const {
    allMarkdownRemark: { edges: sectionsData },
  } = data;

  return (
    <>
      {sectionsData.map(
        ({ node: { frontmatter: elem, html } }, index: number) => {
          if (elem.format == "title") {
            return (
              <React.Fragment key={index}>
                <Seo title={elem.metaTitle as string} description={elem.metaDescription}  />
                <TitleImageIntro title={elem.title} content={html}>
                  <div key={index} className="transition-image row-start-1 row-start-1 col-start-8 col-span-full opacity-20 " data-aos="custom">
                    <StaticImage
                      layout="fullWidth"
                      aspectRatio={1.2/1}
                      objectFit={"cover"}
                      alt="Réflexion membres La Fabrique VingtCinq ordinateur"
                      src={'../../static/about/VingtCinq-Fabrique-digitale-developpeur-coaching-technique.jpg'}
                    />
                    <span className="transition-layer" data-key={index} style={{transitionDelay: '0.3s'}}/>
                  </div>
                </TitleImageIntro>
              </React.Fragment>
            );
          }
          if (elem.format == "banner") {
            return (
              <ImageContent
                key={index}
                title={elem.title}
                subtitle={elem.subtitle}
                content={html}
                imageUrl={elem.imageUrl}
                parallaxImages={elem.parallaxImages}
              />
            );
          }
          if (elem.format == "titles") {
            return (
              <ListTitles
                key={index}
                titles={elem.titles}
                content={html}
                listtitle={elem.listtitle}
                listcontent={elem.listcontent}
              />
            );
          }
          if (elem.format == "staff") {
            return (
              <StaffList
                key={index}
                title={elem.title}
                content={html}
                staffMembers={staffList}
                buttonlabel={elem.buttonlabel}
              />
            );
          }
        }
      )}
    </>
  );
}

export const pageQuery = graphql`
  query AboutPage {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/about/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            format
            title
            subtitle
            imageUrl
            titles
            parallaxImages {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              speed
              direction
              alt
            }
            listtitle
            listcontent
            staff
            buttonlabel
            title
            metaTitle
            metaDescription
            metaImage
          }
        }
      }
    }
  }
`;
