const staffList = [
  {
    id: 1,
    image: "/staff/charles.jpg",
    text: "Fondateur & Associé",
    name: "Charles",
    language: "Django - Python",
    star:true
  },
  {
    id: 2,
    image: "/staff/thomas.jpg",
    text: "Directeur Général & Associé",
    name: "Thomas",
    language: "Business Development - Data",
    star:true
  },
  {
    id: 3,
    image: "/staff/pierre.jpg",
    text: "Directeur Artistique",
    name: "Pierre",
    language: "UX - UI",
    star:true
  },
  {
    id: 4,
    image: "/staff/kenny.jpg",
    text: "Développeur Front-End",
    name: "Kenny",
    language: "Wordpress - React",
    star:false
  },
  {
    id: 5,
    image: "/staff/etienne.jpg",
    text: "Développeur Back-End",
    name: "Étienne",
    language: "Django - Python",
    star:false
  },
  {
    id: 6,
    image: "/staff/sylvain.jpg",
    text: "Directeur technique",
    name: "Sylvain",
    language: "Django - Python",
    star:true
  },
  {
    id: 7,
    image: "/staff/adrien.jpg",
    text: "Développeur Back-End",
    name: "Adrien",
    language: "Django - Python",
    star:false
  },
  {
    id: 8,
    image: "/staff/cesar.jpg",
    text: "Directeur de projet",
    name: "César",
    language: "Stratégie - Conseil",
    star:true
  },
  {
    id: 9,
    image: "/staff/hugues.jpg",
    text: "Développeur Front-End",
    name: "Hugues",
    language: "Wordpress",
    star:false
  },
  {
    id: 10,
    image: "/staff/karl.jpg",
    text: "Développeur Back-End",
    name: "Karl",
    language: "Django - Python",
    star:false
  },
  {
    id: 11,
    image: "/staff/paul.jpg",
    text: "Lead Développeur",
    name: "Paul",
    language: "Wordpress - React",
    star:true
  },
  {
    id: 12,
    image: "/staff/sofiane.jpg",
    text: "Chef de projet",
    name: "Sofiane",
    language: "Stratégie - Conseil",
    star:false
  },
  {
    id: 13,
    image: "/staff/jeremy.jpg",
    text: "Développeur Front-End",
    name: "Jérémy",
    language: "Wordpress - Nuxt",
    star:false
  },
  {
    id: 14,
    image: "/staff/laura.jpg",
    text: "Cheffe de projets Data BI",
    name: "Laura",
    language: "Power BI - Azure",
    star:false
  },
  {
    id: 15,
    image: "/staff/cesarr.jpg",
    text: "Développeur Front-End",
    name: "César",
    language: "Wordpress",
    star:false
  },
  {
    id: 16,
    image: "/staff/alexis.jpg",
    text: "Chef de projet",
    name: "Alexis",
    language: "Stratégie - Conseil",
    star:false
  },
  {
    id: 17,
    image: "/staff/paulhautier.jpg",
    text: "Business Developer",
    name: "Paul",
    language: "Business Development",
    star:false
  },
 
    {
    id: 18,
    image: "/staff/simon.jpg",
    text: "Développeur Back-End",
    name: "Simon",
    language: "Django - Python",
    star:false
  },
  {
    id: 19,
    image: "/staff/martin.jpg",
    text: "Développeur Back-End",
    name: "Martin",
    language: "Django - Python",
    star: false
  },
  {
    id: 20,
    image: "/staff/gael.jpg",
    text: "Directeur BU Data",
    name: "Gaël",
    language: "Stratégie - Data",
    star: false
  },
  {
    id: 21,
    image: "/staff/lucie.jpg",
    text: "Web Designer",
    name: "Lucie",
    language: "UX - UI",
    star: false
  },
  {
    id: 22,
    image: "/staff/ziyed.jpg",
    text: "Business Developer",
    name: "Ziyed",
    language: "Business Development",
    star: false
  }

]


export default staffList;
