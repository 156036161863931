import React from "react";
import classNames from "classnames/bind";
import { isMobile } from "react-device-detect";

type memberProps = {
  imageUrl: string;
  className: string;
  name: string;
  index: number;
};

const MemberBody = ({ imageUrl, className, name, index }: memberProps) => (
  <svg
    role="img"
    aria-labelledby="membername"
    id={"member" + index}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="none"
    viewBox="0 0 158 159"
    className={className}
  >
    <title id="membername">Portrait {name} La Fabrique VingtCinq </title>
    {(isMobile ? [1, 2].includes(index % 4) : index % 2 === 0) ? (
      //shadow right
      <path
        fill="#010202"
        stroke="#010202"
        className="duration-03 ease-in-out transition-transform	origin-center	group-hover:-rotate-2"
        d="M153.633 150.402c0 .134-.056.308-.251.53-.198.225-.516.471-.975.73-.918.517-2.303 1.032-4.133 1.531-3.652.997-8.961 1.899-15.547 2.659-13.166 1.52-31.369 2.46-51.484 2.46-20.115 0-38.318-.94-51.484-2.46-6.587-.76-11.895-1.662-15.547-2.659-1.83-.499-3.216-1.014-4.133-1.531-.46-.259-.777-.505-.975-.73-.195-.222-.252-.396-.252-.53s.057-.308.252-.53c.198-.224.516-.471.975-.729.917-.518 2.303-1.033 4.133-1.532 3.652-.997 8.96-1.899 15.547-2.659 13.166-1.52 31.369-2.46 51.484-2.46 20.115 0 38.318.94 51.484 2.46 6.586.76 11.895 1.662 15.547 2.659 1.83.499 3.215 1.014 4.133 1.532.459.258.777.505.975.729.195.222.251.396.251.53Z"
      />
    ) : (
      //shadow left
      <path
        data-path="shadow-left"
        fill="#010202"
        stroke="#010202"
        className="duration-03 ease-in-out transition-transform	origin-center	group-hover:rotate-2"
        d="M4.367 150.402c0 .134.056.308.252.53.198.225.515.471.974.73.918.517 2.304 1.032 4.134 1.531 3.652.997 8.96 1.899 15.546 2.659 13.167 1.52 31.37 2.46 51.484 2.46 20.116 0 38.318-.94 51.485-2.46 6.586-.76 11.894-1.662 15.546-2.659 1.831-.499 3.216-1.014 4.134-1.531.459-.259.777-.505.975-.73.195-.222.251-.396.251-.53s-.056-.308-.251-.53c-.198-.224-.516-.471-.975-.729-.918-.518-2.303-1.033-4.134-1.532-3.652-.997-8.96-1.899-15.546-2.659-13.167-1.52-31.37-2.46-51.484-2.46-20.116 0-38.318.94-51.485 2.46-6.586.76-11.894 1.662-15.546 2.659-1.83.499-3.216 1.014-4.134 1.532-.459.258-.776.505-.974.729-.196.222-.252.396-.252.53Z"
      />
    )}
    {(isMobile ? [1, 2].includes(index % 4) : index % 2 === 0) ? (
      // body white
      <path
        fill="#fff"
        stroke="#010202"
        className="duration-03 ease-in-out transition-transform	origin-center	group-hover:rotate-6"
        d="M129.698 86.682c15.24 11.987 25.023 29.471 27.248 48.67L14.611 152.059l-5.649.663c-2.01-19.237 3.771-38.566 16.096-53.757 12.386-15.342 30.363-25.259 49.988-27.563 19.626-2.303 39.287 3.196 54.652 15.28Z"
      >
      </path>
    ) : (
      //body yellow
      <path
        fill="#FFE03E"
        stroke="#010202"
        className="duration-03 ease-in-out transition-transform	origin-center group-hover:-rotate-6"
        d="M28.303 86.682c-15.24 11.987-25.024 29.471-27.248 48.67l142.335 16.707 5.648.663c2.01-19.237-3.771-38.566-16.096-53.757-12.386-15.342-30.362-25.259-49.988-27.563-19.625-2.303-39.287 3.196-54.651 15.28Z"
      />
    )}
    <path
      fill={`url(#image-${index})`} stroke="black"
      className={classNames("duration-03 ease-in-out transition-transform origin-center",{
        'group-hover:rotate-6' : index % 2 == 0,
        'group-hover:-rotate-6' : index % 2 == 1
      })}

      d="M126.542 43.42c2.134 25.882-17.333 48.82-43.494 51.22-26.162 2.401-49.092-16.645-51.227-42.529C29.686 26.228 49.153 3.291 75.315.891c26.162-2.402 49.092 16.645 51.227 42.528Z"
    />
    <pattern id={'image-' + index} width="1" height="1" viewBox="0 0 50 50" patternUnits="objectBoundingBox">
      <image  width="50" height="50"  xlinkHref={imageUrl} preserveAspectRatio="none"/>
    </pattern>
  </svg>
);
export default MemberBody;

