import React from "react";
import Section from "components/section";
import { TitlesFrontMatter } from "types/graphql";
import Heading from "../headings";

type titlesProps = Omit<TitlesFrontMatter, "format"> & {
  content?: string;
  tagheading?: string;
};

const listTitles = ({
                      listtitle,
                      listcontent,
                      titles,
                      content,
                      tagheading = "h3"
}: titlesProps) => {
  return (
    <Section data-name="list-titles" className="bg-gray-50 js-section" full>
      <ul
        className={
          `flex flex-wrap gap-y-3 mb-6 lg:mb-12 ` +
          (titles.length > 3 ? "" : "justify-center")
        }
      >
        {titles.map((title: string, index: number) => (
          <li
            key={index}
            data-aos="custom"
            className="flex items-center after:content-['+'] after:text-2xl after:text-gray-200 after:px-2.5 last:after:hidden"
            style={{transitionDelay: `${(index * 0.1) }s`}}
          >
            <Heading className="h2 border border-gray-100 rounded-xl px-2 pt-0.5 lg:pt-2.5 bg-white inline-block" headingLevel={tagheading }>
              {title}
            </Heading>
          </li>
        ))}
      </ul>
      {listtitle && (
        <ul className="grid grid-cols-1 lg:grid-cols-4 gap-7">
          {listtitle.map((title: string, index: number) => (
            <li
              key={index}
              data-aos="custom"
              style={{transitionDelay: `${0.25 + (index * 0.2) }s`}}
            >
              <h4 className="h4 subtitle1 text-gray-900 mb-1">
                {title}
              </h4>
              {listcontent
                .filter((el: string, i: number) => i === index)
                .map((content: string, subindex: number) => (
                  <p className="text-b3 text-gray-700" key={subindex}>
                    {content}
                  </p>
                ))}
            </li>
          ))}
        </ul>
      )}
      {content && (
        <div
          className="text-center"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </Section>
  );
};

export default listTitles;
